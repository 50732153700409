import { DynamicUserProfile } from "@/types/dynamic";
import { useTranslation } from "react-i18next";

// TODO: resolve ENS if possible
const generateOwnedBy = (user: DynamicUserProfile) => {
  if (user?.first_name && user?.last_name) {
    return `${user.first_name} ${user.last_name}`;
  } else if (user?.username) {
    return user.username;
  } else if (user?.email) {
    return user.email;
  } else {
    return "Legitimate User";
  }
};

interface OwnedByProps {
  user: DynamicUserProfile;
}

const OwnedBy: React.FC<OwnedByProps> = ({ user }) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-1.5 py-[9px] px-[25px] bg-[#FF7557] rounded-[9px] text-black text-xs justify-center items-center h-[29px]">
      <p className="uppercase">{t("owned-by.title", "Owned By")}</p>
      <span className="w-4"/>
      <p className="font-morningMemoriesScriptAlt normal-case text-3xl leading-2 ">
        {generateOwnedBy(user)}
      </p>
    </div>
  );
};

export default OwnedBy;
