import { Params, redirect } from "react-router-dom";
import { WALLET_ADDRESS_REGEX } from "@/utils/validation";
import { DynamicUserProfile } from "@/types/dynamic";

const API_URL = "https://metadata-api.legitimate.tech";

export const collectionLoader = async ({
  params: { walletAddressOrUsername },
}: {
  params: Params<string>;
}) => {
  if (!walletAddressOrUsername) {
    return redirect("/");
  }

  let walletAddress;
  let user: DynamicUserProfile | undefined = undefined;

  if (WALLET_ADDRESS_REGEX.test(walletAddressOrUsername)) {
    walletAddress = walletAddressOrUsername;

    const response = await fetch(
      `${API_URL}/owners/dynamic-user?address=${walletAddressOrUsername}`,
    );

    const data = await response.json();

    if (data?.username) {
      return redirect(`/${data.username}`);
    }

    user = data;
  } else {
    const response = await fetch(
      `${API_URL}/owners/dynamic-user?username=${walletAddressOrUsername}`,
    );

    const data = await response.json();

    if (data?.address) {
      walletAddress = data.address;
      user = data;
    } else {
      return redirect("/");
    }
  }

  const response = await fetch(
    `https://metadata-api.legitimate.tech/tags/collection/${walletAddress}`,
  );

  const { tags } = await response.json();

  return { user, tags };
};
